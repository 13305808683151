/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */
.container {
  @apply max-w-7xl mx-auto px-4;
}

html {
  scroll-behavior: smooth;
}
